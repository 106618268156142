<template>
  <div id="changeForm" class="fxt-content" style="max-height:100vh">
    <div class="text-center mb-5 mb-lg-10">
      <h3 class="mt-5">{{ $t('CHANGE.FORM.TITLE') }}</h3>
      <p class="text-muted font-weight-bold">
        {{ $t('CHANGE.FORM.MESSAGE') }}
      </p>
    </div>

    <form class="form" @submit.stop.prevent="onSubmit">

      <!--start::Error Handling-->
      <ErrorBox :errors="authenticationErrors" @clear_errors="clearErrors">
      </ErrorBox>
      <!--end::Error Handling-->

      <!--begin::Username-->
      <InputGeneric
        id="input-email"
        class="mb-7"
        :model="$v.form.email"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.EMAIL')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.EMAIL')"
        :error="$t('FORMS.ERROR.EMAIL')"
        type="email"
      >
      </InputGeneric>
      <!--end::Username-->

      <!--begin::Password-->
      <InputGenericPassword
        id="input-password"
        class="mb-7"
        :model="$v.form.password"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.PASSWORD')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.PASSWORD')"
        :error="$t('FORMS.ERROR.PASSWORD')"
      >
      </InputGenericPassword>
      <!--end::Password-->

      <!--begin::Password Confirmation-->
      <InputGenericPassword
        id="input-password-confirm"
        class="mb-0"
        :model="$v.form.password_confirmation"
        alignLabel="left"
        :placeholder="$t('FORMS.INPUTS.PASSWORD_CONFIRM')"
        :showInline="false"
        :submissionStates="submissionStates"
        :label="$t('FORMS.INPUTS.PASSWORD_CONFIRM')"
        :error="$t('FORMS.ERROR.PASSWORD_CONFIRM')"
      >
      </InputGenericPassword>
      <!--end::Password Confirmation-->

      <!--begin::Actions-->
      <div class="form-group d-flex flex-wrap justify-content-center align-items-center mt-15">
        <button
          class="btn btn-lg btn-outline-primary mr-2"
          :disabled="submissionStates.submitting === true"
          type="button"
          style="width:40%"
          @click="toLogin()"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-lg btn-primary ml-2"
          :disabled="submissionStates.submitting === true"
          type="submit"
          style="width:40%"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </button>
      </div>
      <!--end::Actions-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import { RESET, CLEAR, RESET_PASSWORD } from "@/modules/together-authentication/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required, minLength, maxLength, requiredIf, sameAs } from "vuelidate/lib/validators";

import ErrorBox from "@/view/components/ErrorBox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericPassword from "@/modules/together-helpers/components/generic-inputs/GenericPasswordInput";

export default {
  mixins: [validationMixin],

  name: "password-change",

  data() {
    return {
      error: {
        active: false,
        message: ""
      },
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: ""
      },
      submissionStates: {
        submitting: false
      },
    };
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(24),
        valid: function(value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /['^£!$%&*()}{@#~?><>,.|=_+¬-]/.test(value)
          return containsUppercase && containsNumber && containsSpecial
        }
      },
      password_confirmation: {
        required: requiredIf(function() {
          return !this.hasCompletedAccountSetup;
        }),
        sameAsPassword: sameAs(function() {
          return this.form.password;
        })
      }
    }
  },

  components: {
    ErrorBox,
    InputGeneric,
    InputGenericPassword
  },

  mounted() {
    // check if current user is authenticated, redirect them to the dashboard if they are
    if (this.isAuthenticated) {
      this.$router.push({ name: "dashboard" });
    }
    else {
      this.$store.dispatch(CLEAR);
      this.setTokenFromURL();
    }
  },

  methods: {
    setTokenFromURL() {
      let token = this.$route.params.token;
      token = token.replace("token=","");

      this.form.token = token;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    toLogin() {
      this.$store.dispatch(RESET)
      .then(() => {
        this.$router.push({ name: "login" })
      })
    },
    resetForm() {
      this.form.email = "";
      this.form.password = "";
      this.form.password_confirmation = "";
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch(CLEAR);
      this.submissionStates.submitting = true;

      const email = this.form.email;
      const password = this.form.password;
      const password_confirmation = this.form.password_confirmation;
      const token = this.form.token;

      this.$store.dispatch(RESET_PASSWORD, { email, password, password_confirmation, token })
        .then(() => {
          this.resetForm();
          this.toLogin();
        })
        .catch(() => {
          this.form.password = "";
          this.form.password_confirmation = "";
        })
        .finally(() => {
          this.submissionStates.submitting = false;
          this.$v.$reset();
        })
    },
    clearErrors: function() {
      this.$store.dispatch(CLEAR);
    }
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),

    ...mapGetters([
      "isAuthenticated",
      "currentUser",
      "projectBrand",
      "authenticationErrors"
    ]),

    isProjectDomain: function() {
      let subDomain = window.location.host.split('.')[0];
      if (subDomain.indexOf("-") >= 0) {
        return true;
      } else {
        return false;
      }
    },

    calculatedClassList: function() {
      let classList = "";

      if (this.error.active) {
        classList = classList + "bordered-card-danger";
      } else {
        classList = classList + "bordered-card-primary";
      }

      let columnsFWLogin = " col-xs-12 col-sm-12 col-md-6 col-xxl-3";
      let columnsSBLogin = " col-xs-12 col=sm-12 col-md-6 col-xxl-5";

      if (this.isProjectDomain) {
        if (this.projectBrand[1].value == "1" || this.projectBrand[2].project_asset.length == 0) {
          classList = classList + columnsFWLogin;
        } else {
          classList = classList + columnsSBLogin;
        }
      } else {
        classList = classList + columnsFWLogin;
      }

      return classList;
    }
  }
};
</script>